function onClick( elementSelector, handler) {
    var eventName = 'click';

    document.addEventListener(eventName, function(e) {
        // loop parent nodes from the target to the delegation node
        for (var target = e.target; target && target != this; target = target.parentNode) {
            if (target.matches(elementSelector)) {
                handler.call(target, e);
                break;
            }
        }
    }, false);
}

function domReady( callback ) {
      if (
          document.readyState === "complete" ||
          (document.readyState !== "loading" && !document.documentElement.doScroll)
      ) {
        callback();
      } else {
        document.addEventListener("DOMContentLoaded", callback);
      }
}

// lightboxes

var lightboxes = document.querySelectorAll('.lightbox-wrapper');

if ( lightboxes ) {
    Array.prototype.forEach.call( lightboxes, function(lightbox, i){
        var openLightbox = lightbox.querySelectorAll('[data-lightbox-open]')[0];
        var closeLightbox = lightbox.querySelectorAll('[data-lightbox-close]')[0];
        var lightboxEl = lightbox.querySelectorAll('.lightbox')[0];

        openLightbox.addEventListener('click',function(e) {
            lightboxEl.classList.remove('hide');
            lightboxEl.classList.add('show');
        });

        closeLightbox.addEventListener('click',function(e) {
            lightboxEl.classList.add('hide');
            // lightboxEl.classList.remove('show');
        });

    });
}


// nav

var menuToggle = document.querySelectorAll('[data-menu-toggle]')[0];

if ( menuToggle ) {
    menuToggle.addEventListener('click', function( e ) {
        e.preventDefault();
        var nav = document.querySelectorAll('[data-nav-wrapper]')[0];
        var menuToggle = document.querySelectorAll('[data-menu-toggle]')[0];
        // nav.classList.toggle('show');

        if ( nav.classList.contains('desktop-show') && window.innerWidth > 768 ) {

            nav.classList.add('hide');

            menuToggle.parentNode.classList.remove('desktop-show');
            menuToggle.parentNode.classList.remove('show');

            setTimeout(function(){
                var nav = document.querySelectorAll('[data-nav-wrapper]')[0];
                nav.classList.remove('desktop-show');
                nav.classList.remove('show');

            }, 500);



        } else if ( nav.classList.contains('show') && nav.classList.contains('hide') ) {
            nav.classList.remove('hide');
            nav.classList.remove('show');
            nav.classList.add('show');
            menuToggle.parentNode.classList.add('show');

        } else if ( nav.classList.contains('show') ) {
            nav.classList.add('hide');
            // nav.classList.remove('show');
            menuToggle.parentNode.classList.remove('show');
        } else {
            nav.classList.remove('hide');
            nav.classList.add('show');
            menuToggle.parentNode.classList.add('show');
        }


    })
}

var slider = false;

function setupSlideshow() {

    console.log('setupSlideshow');

    // horizontal slider
    var horizontalSlider = document.querySelectorAll('.horizontal-slider')[0];

    if ( horizontalSlider && window.innerWidth > 768 ) {

        console.log( "setup slider" );

        if ( !slider ) {

            var startIndex = parseInt(horizontalSlider.dataset.startindex);

            slider = tns({
                // loop:       false,
                startIndex: startIndex,
                container:  '.horizontal-slider',
                items:      1,
                // slideBy:    'page',
                autoplay:   false,
                controls:   false,
                nav:        false,
                arrowKeys:  true,
                autoWidth:  true
            });



            // horizontalSlider.addEventListener('mousemove', function(e) {
            //     var ww = window.innerWidth;
            //     if ( e.x < ww/2 ) {
            //         // left
            //         horizontalSlider.classList.remove('right-hover');
            //         horizontalSlider.classList.add('left-hover');
            //     } else {
            //         // right
            //         horizontalSlider.classList.remove('left-hover');
            //         horizontalSlider.classList.add('right-hover');
            //     }
            // });

            onClick('.next', function(e) {
                slider.goTo('next');
            });
            onClick('.prev', function(e) {
                slider.goTo('prev');
            });

            onClick('.horizontal-slider', function(e) {
                e.stopPropagation();
                var ww = window.innerWidth;
                console.log('e', e );
                if ( e.x < ww/2 ) {
                    // left
                    console.log('left');
                    slider.goTo('prev');
                } else {
                    // right
                    console.log('right');
                    slider.goTo('next');
                }

            });

        }
    } else if ( horizontalSlider ) {

        console.log( "small window" );

        console.log( 'slider', slider );

        if ( slider ) {
            console.log( 'destroy' );
            slider.destroy();
        }

        if (document.location.pathname !== '/') {
            window.addEventListener('load', function() {
                var horizontalSlider = document.querySelectorAll('.horizontal-slider')[0];
                console.log("ready", horizontalSlider);

                horizontalSlider.scrollIntoView({behavior: 'smooth'});
            } );
        } else {
            var scrollButton = document.querySelectorAll('.scroll-down')[0];
            scrollButton.classList.add('show');
            onClick('.scroll-down', function(e) {
                var scrollButton = document.querySelectorAll('.scroll-down')[0];
                scrollButton.classList.remove('show');
                var horizontalSlider = document.querySelectorAll('.horizontal-slider')[0];
                horizontalSlider.scrollIntoView({behavior: 'smooth'});
            });
        }

    }
}

setupSlideshow();

// window.addEventListener("resize", function() {

// });

var resizeTimer;

window.addEventListener('resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    // Run code here, resizing has "stopped"
    setupSlideshow();

  }, 250);

});



function setHeight() {

    var main = document.querySelectorAll('main')[0];

    if ( window.innerWidth > 768 ) {

        var header = document.querySelectorAll('header')[0];

        var mainHeight = window.innerHeight - header.clientHeight;

        main.style = "height:" + mainHeight + "px;"
    } else {
        main.style = "";
    }

}

window.addEventListener('load',function(){
    setHeight();
});

window.addEventListener('resize',function(){
    setHeight();
});

function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function scrollSticky() {
    var navContainer = document.querySelectorAll('[data-nav-el]')[0];
    var slideshow = document.querySelectorAll('.horizontal-slider')[0];

    var scrollButton = document.querySelectorAll('.scroll-down')[0];
    scrollButton.classList.remove('show');


    if ( navContainer ) {

        var windowScrollTop = window.pageYOffset;
        var divOffset = offset( slideshow );

        // console.log( windowScrollTop, divOffset.top );

        if (  divOffset.top < windowScrollTop && window.innerWidth <= 768 ) {
            navContainer.classList.add('sticky');
        } else {
            navContainer.classList.remove('sticky');
        }
    }
}

window.addEventListener('scroll',function(){
    scrollSticky();
});
